<template>
  <div class="bg bg23">
    <div class="class--con">
      <class-but i-s-throw-out @backOfff="backOfff" @forward="forward"/>
      <div class="colour">
        <img src="../../../assets/image/conference/xshg-title.png" alt="">
        学生成长回顾（{{ courseStudentGrowUpListIndex + 1 }}/{{ courseStudentGrowUpList.length }}）
      </div>
      <div>
        <div class="class--container" v-if="courseStudentGrowUpList.length > 0">
          <div class="user_img">
            <!-- 图一-->
            <img  v-if="imageLoaded"
                  :class="{'portrait': isPortrait, 'landscape': !isPortrait}"  :src="courseStudentGrowUpList[courseStudentGrowUpListIndex].imgOne" alt="">
          </div>
          <div class="user-info">
            <div class="user-info__name">{{ courseStudentGrowUpList[courseStudentGrowUpListIndex].studentName }}</div>
            <div class="user-info__attendance">
              <div class="user-info__attendance mgl-20">
                <span class="user-info__attendance--title">出勤：</span>
                <span class="user-info__attendance--value">{{ courseStudentGrowUpList[courseStudentGrowUpListIndex].attendance }}</span>
              </div>
              <div class="user-info__attendance">
                <span class="user-info__attendance--title">完成作品：</span>
                <span class="user-info__attendance--value">{{ courseStudentGrowUpList[courseStudentGrowUpListIndex].workNum }}</span>
              </div>
            </div>
            <div class="user-info__studyTagList">
              <span class="user-info__studyTagList--item" v-for="(item, index) in courseStudentGrowUpList[courseStudentGrowUpListIndex].studyTagList" :key="index">
                <img src="../../../assets/image/conference/xshg-dui.png" alt="">
                {{item}}
              </span>
            </div>
            <div class="user-info__imgTwo">
              <img  v-if="imageLoaded1"
                    :class="{'portrait1': isPortrait1, 'landscape1': !isPortrait1}"  :src="courseStudentGrowUpList[courseStudentGrowUpListIndex].imgTwo" alt="">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";

import service from "@/utils/axios";

export default {
  name: "ninePage",
  components: {
    ClassBut,
  },
  data() {
    return {
      value1: 0,
      courseStudentGrowUpList: [],
      courseStudentGrowUpListIndex: 0,
      isPortrait: false,  // 是否竖图
      imageLoaded: false,  // 图片是否加载完成
      isPortrait1: false,  // 是否竖图
      imageLoaded1: false  // 图片是否加载完成
    }
  },
  created() {
    this.getApicourseStudentGrowUp()
  },
  methods: {
    forward() {
      if (this.courseStudentGrowUpListIndex + 1 < this.courseStudentGrowUpList.length) {
        this.courseStudentGrowUpListIndex++
        this.loadImage(this.courseStudentGrowUpList[this.courseStudentGrowUpListIndex].imgOne)
        this.loadImage1(this.courseStudentGrowUpList[this.courseStudentGrowUpListIndex].imgTwo)
      } else if (this.courseStudentGrowUpListIndex + 1 === this.courseStudentGrowUpList.length) {
        //学生成长回顾
        this.$router.push({
          name: 'studyBlessing',
          query: this.$route.query,
        })
      }
    },
    loadImage(url) {
      const img = new Image();
      img.onload = () => {
        this.isPortrait = img.height > img.width;  // 竖图：高度 > 宽度
        this.imageLoaded = true;
        console.log(this.isPortrait)
      };
      img.onerror = () => {
        console.error('图片加载失败');
        this.imageLoaded = false;
      };
      img.src = url;  // 设置图片源
    },
    loadImage1(url) {
      const img = new Image();
      img.onload = () => {
        this.isPortrait1 = img.height > img.width;  // 竖图：高度 > 宽度
        this.imageLoaded1 = true;
      };
      img.onerror = () => {
        console.error('图片加载失败');
        this.imageLoaded1 = false;
      };
      img.src = url;  // 设置图片源
    },
    async getApicourseStudentGrowUp() {
      let res = await service.post('/dwart/an_teaching/hbk/v1/growthReviewList', {
        classId: this.$route.query.classId,
      })
      this.courseStudentGrowUpList = res
      if ( res.length > 0) {
        this.loadImage(res[0].imgOne)
        this.loadImage1(res[0].imgTwo)
      }
      console.log(res.length)
      if (res.length === 0) {
        await this.$router.replace({
          name: 'studyBlessing',
          query: this.$route.query,
        })
      }
    },
    backOfff() {
      if (this.courseStudentGrowUpListIndex > 0) {
        this.courseStudentGrowUpListIndex--
        this.loadImage(this.courseStudentGrowUpList[this.courseStudentGrowUpListIndex].imgOne)
        this.loadImage1(this.courseStudentGrowUpList[this.courseStudentGrowUpListIndex].imgTwo)
      } else {
        this.$router.back(-1)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.class--container {
  display: flex;
  margin-top: 80px;
    .user_img{
      margin-left: 257px;
      margin-right: 100px;
      .landscape {
        width: 800px;
        height:auto;
        border: 1px dashed #FF9000;  /* 可以根据需要更改样式 */
        object-fit: contain;
        padding: 14px;
      }
      .portrait {
        width: auto;
        height: 594px;
        border: 1px dashed #FF9000;  /* 可以根据需要更改样式 */
        object-fit: contain;
        margin-left: 125px;
        padding: 14px;
        box-sizing: border-box;
      }
    }
    .user-info{
      .user-info__name{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 46px;
        color: #333333;
        line-height: 65px;
      }
      .user-info__attendance{
        display: flex;
        .mgl-20{
          margin-right: 40px;
        }
        .user-info__attendance{
          .user-info__attendance--title{
            color: #777777;
            font-size: 36px;
          }
          .user-info__attendance--value{
            color: #FF8035;
            font-size: 36px;
          }
        }
      }
      .user-info__studyTagList{
        width: 366px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 30px;
        color: #FF9000;
        .user-info__studyTagList--item{
          margin-top: 20px;
        }
        img{
          width:32px;
          height: 32px;
        }
      }
      .user-info__imgTwo{

        .landscape1 {
          width: 358px;
          height:auto;
          border: 1px dashed #FF9000;  /* 可以根据需要更改样式 */
          object-fit: contain;
          padding: 14px;
          margin-top: 89px;
        }
        .portrait1 {
          width: auto;
          height: 260px;
          border: 1px dashed #FF9000;  /* 可以根据需要更改样式 */
          object-fit: contain;
          //margin-left: 125px;
          padding: 14px;
          box-sizing: border-box;
          margin-top: 20px;
        }
      }
    }
}

.colour {
  display: flex;
  align-items: center;
  color: #2B3654;
  font-size: 42px;
  font-weight: bold;
  margin-top: 114px;
  margin-left: 257px;
  img {
    width: 40px;
    height: 40px;
  }
}


::-webkit-scrollbar {
  display: none;
}

</style>
